<template>
  <v-container class="heading-container">
    <h2 class="title text-uppercase">{{this.title}}</h2>
    <v-icon class="icon">{{this.icon}}</v-icon>
  </v-container>
</template>

<script>
import gsap from 'gsap';

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: 'fas fa-star',
    },
  },
  methods: {
    // Animations using GSAP
    headingBeforeEnter(el) {
      gsap.from(el, {
        y: '-200px',
      });
    },
    headingEntered(el) {
      gsap.to(el, {
        duration: 1,
        y: 0,
        ease: 'bounce',
      });
    },
    iconBeforeEnter(el) {
      gsap.from(el, {
        opacity: 0,
      });
    },
    iconEntered(el) {
      gsap.to(el, {
        duration: 2,
        opacity: 1,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .heading-container {
    display: flex;
    justify-content: space-between;
    margin-top: 2.5rem;
    margin-bottom: 1.5rem;
    border-bottom: #828282 solid 1px;
  }

  .title,
  .icon {
    font-size: 2.5rem !important;
    color: #5078FF !important;
  }

  @media (min-width: 400px) and (max-width: 800px) {
    .heading-container {
      margin-top: 2rem;
      margin-bottom: 1.25rem;
      border-bottom: #828282 solid 1px;
    }

    .title,
    .icon {
      font-size: 2rem !important;
    }
  }
  @media (min-width: 300px) and (max-width: 399px) {
    .heading-container {
      margin-top: 1.5rem;
      margin-bottom: 1rem;
      border-bottom: #828282 solid 1px;
    }
    .title,
    .icon {
      font-size: 1.7rem !important;
    }
  }
</style>
